import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import DeleteModal from '../../../Components/Common/DeleteModal'

//import Components
import DisableModal from '../../../Components/Common/DisableModal';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../store/actions';
import TableContainer from '../../../Components/Common/TableContainer';
import bussinessLogo from '../../../assets/images/companies/img-2.png'
import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';
import Constant from '../../../utils/constant';
import useWindowDimension from '../../../Components/Hooks/useWindowDimension';
import ChangingProgressProvider from '../../../Components/Common/ChangingProgressProvider';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { allLocation, allBusinessList, allConnectorStationId, resendLocationInvite, LocManBus } from '../../../store/actions';
import { getUserRole, getValueFromObject } from '../../../helpers/helper.service';
import CustomButton from '../../../Components/Common/CustomButton';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import ContentLoader from '../../../Components/Common/ContentLoader';
import { ClickTrigger } from 'lord-icon-element';

const Location = (props) => {
    document.title = "Location | Vidhyutam";
    const history = useHistory();
    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabledModal, setDisabledModal] = useState(false);
    const [enabledModal, setEnabledModal] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [selectValue, setSelectValue] = useState(false);
    const [locationData, setLocationData] = useState('');
    const [IsShowMap, setIsShowMap] = useState(false);
    const [businessName, setBusinessName] = useState([]);
    const [locationListData, setLocationListData] = useState([]);
    const [showNoData, setShowNoData] = useState(false);
    const [headerHeight, setHeaderHeight] = useState('');
    const [footerHeight, setFooterHeight] = useState('');
    const [breadCrumb, setBreadCrumbHeight] = useState('');
    const [cardHeader, setCardHeaderHeight] = useState('');
    const [noOfConnectors, setNoOfConnectors] = useState('');
    const [noOfStation, setNoOfStation] = useState('');
    const [filteredTable, setfilteredTable] = useState([]);
    const [mapLocationEntry, setMapLocationEntry] = useState([]);
    const [locationLoader, setLocationLoader] = useState(false);

    // handle filtered table counting
    const handleFilterCount = () => {
        let count = 0;
        let Connectors = 0
        filteredTable.forEach((item) => {
            count = count + item.original.nStations;
            Connectors = Connectors + item.original.nConnectors;
        })
        setNoOfConnectors(Connectors)
        setNoOfStation(count)
    }

    useEffect(() => {
        handleFilterCount();
    }, [filteredTable])

    useEffect(() => {
        setTimeout(() => {
            setLocationLoader(true)
        }, 2000)
    }, [])


    useEffect(() => {
        dispatch(allLocation('?id='));

        // if (getUserRole() !== 'LM') {
        if (JSON.parse(window.localStorage.getItem('users'))?.userRole !== 'LocationManager') {
            dispatch(allBusinessList('?id='));
        } else {
            let userID;
            if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
                userID = JSON.parse(window.localStorage.getItem('users'))?.userId;
            } else if (window.sessionStorage.getItem('users') !== undefined && window.sessionStorage.getItem('users') !== null) {
                userID = JSON.parse(window.sessionStorage.getItem('users'))?.userId;
            }

            userID && dispatch(LocManBus(userID));
        }
    }, []);

    const { locationList, businessList, BusinessDetail, loading } = useSelector((state) => ({
        locationList: state?.locationsList?.allLocationList.filter(
            (i) => i.status != "Deleted"
        ),
        businessList: state?.businessList?.allBusinessList.filter(
            (i) => i.status != "Deleted"
        ),
        BusinessDetail: state?.locationsList?.LocManBussDetail[0],
        loading: state.Toast.loader
    }));

    console.log('loading', loading);

    useEffect(() => {
        if (businessList?.length > 0) {
            const BusinessName = setBusiness();
            setBusinessName([...BusinessName]);
        }
    }, [JSON.stringify(businessList)]);

    const setBusiness = () => {
        const tempArray = [{ id: '0', value: 'All', BusinessID: 'All' }]
        const businessName = businessList.map((eve, ind) => {
            return {
                id: `${ind + 1}`,
                value: eve.businessName,
                BusinessID: eve.businessId
            }
        })
        return tempArray.concat(businessName);
    }


    useEffect(() => {
        if (locationList.length > 0) {
            setLocationListData(locationList);
            const numOfConn = locationList?.map((e, i) => e.nConnectors).reduce((Acc, e) => Acc + e, 0);

            setNoOfConnectors(numOfConn);

            const numOfStat = locationList?.map((e, i) => e.nStations).reduce((Acc, e) => Acc + e, 0);

            setNoOfStation(numOfStat);
        }
    }, [JSON.stringify(locationList)])



    useEffect(() => {
        const locationEntreies = locationList?.map((e, i) => {
            return {
                id: e?.locationId,
                center: {
                    lat: e?.latitude,
                    lng: e?.longitude
                },
                value: e?.nStations
            }
        })
        setMapLocationEntry(locationEntreies);
    }, [JSON.stringify(locationList)]);


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Constant.GOOGLEMAPKEY.ApiKey,
        libraries: "places"
    })


    const bussinessDropdownValue = [
        {
            id: '1',
            value: 'All'
        },
        {
            id: '2',
            value: 'Titania Pvt Ltd'
        }
    ]

    const handleViewDetailClick = (e) => {

        history.push('/location/viewlocation', {
            state: {
                value: e?.original?.locationId
            }
        });
    }
    const handleAddButtonClicks = () => {
        history.push('/location/addlocation', {
            // state: {
            //     businessID: selectValue?.BusinessID
            // }
        });
    }
    useEffect(() => {
        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setBreadCrumbHeight(
            document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    }, [IsShowMap]);
    const handleResize = () => {

        setHeaderHeight(
            document.getElementsByClassName('navbar-header')[0]?.offsetHeight
        );
        setFooterHeight(
            document.getElementsByClassName('footer')[0]?.offsetHeight
        );
        setBreadCrumbHeight(
            document.getElementsByClassName('breadCrumb')[0]?.offsetHeight
        );
        setCardHeaderHeight(
            document.getElementsByClassName('cardHeader')[0]?.offsetHeight
        );
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    const stationMarkerData = [
        {
            id: '1',
            center: {
                lat: 23.09217141775265,
                lng: 72.59736133797448
            },
            value: 8,
        },
        {
            id: '2',
            center: {
                lat: 23.092884517294213,
                lng: 72.59414234358199
            },
            value: 9,
        },
        {
            id: '3',
            center: {
                lat: 23.069088999861684,
                lng: 72.58104667549789
            },
            value: 6,
        },
        {
            id: '4',
            center: {
                lat: 23.03159749043042,
                lng: 72.53342401508877
            },
            value: 10,
        },
        {
            id: '4',
            center: {
                lat: 23.02700800133195,
                lng: 72.67343810315047
            },
            value: 1,
        },
    ]
    const center = {
        lat: 23.09217141775265,
        lng: 72.59736133797448
    }
    // Column

    // custom sort fn
    const handleBusinesNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return rowA.values.businessName.toLowerCase() > rowB.values.businessName.toLowerCase() ? 1 : -1;
        }
    }, [])

    const handleLocationNameSort = React.useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return rowA.values.locationName.toLowerCase() > rowB.values.locationName.toLowerCase() ? 1 : -1;
        }
    }, [])

    const [buttonIndex, setButtonIndex] = useState(-1)

    const columns1 = useMemo(

        () => [
            {
                Header: "Name",
                accessor: "locationName",
                filterable: true,
                sortType: handleLocationNameSort,
                show: true,
                Cell: (cell) => {
                    return <p className='text'>{cell.value}</p>;
                },
            },

            {
                Header: "Business Name",
                accessor: "businessName",
                filterable: true,
                sortType: handleBusinesNameSort,
                show: true,
                Cell: (cell) => {
                    return <p className='text wordBreak'>{cell.value}</p>;
                },
            },
            {
                Header: "Region, City",
                accessor: d => `${d.region} ${d.city}`,
                filterable: true,
                disableSortBy: true,
                show: true,
                Cell: (address) => {
                    return (
                        <p className='text wordBreak'>
                            {address.row.original.region
                                ? `${address.row.original.region}${address.row.original.city ? `, ${address.row.original.city}` : ''}`
                                : address.row.original.city
                                    ? address.row.original.city
                                    : '-'}
                        </p>
                    );                     // locationDetails?.region ? `${getValueFromObject('region', locationDetails, '')}, ${getValueFromObject('city', locationDetails, '')}` : `${getValueFromObject('city', locationDetails, '')}`
                },
            },
            {
                Header: "Location Manager",
                accessor: d => `${d.lFirstName} ${d.lLastName} ${d.lEmail} ${d.lContactNumber}`,
                filterable: true,
                disableSortBy: true,
                show: true,
                Cell: (locationManager) => {
                    return <div className='managerBox'>
                        <p className='name textOverflow'>{getValueFromObject('lFirstName', locationManager.row.original, '')} {getValueFromObject('lLastName', locationManager.row.original, '')}</p>
                        <p className='email textOverflow'>{getValueFromObject('lEmail', locationManager.row.original, '')}</p>
                        <p className='number textOverflow'>{`+${getValueFromObject('lCountryCode', locationManager.row.original, '')} ${getValueFromObject('lContactNumber', locationManager.row.original, '')}`}</p>
                    </div>;
                },
            },
            {
                Header: dimensions.width > 1600
                    ? `Station (${noOfStation ? noOfStation : '0'})`
                    : `Counts`,
                accessor: "nStations",
                filterable: true,
                disableGlobalFilter: true,
                show: true,
                Cell: (cell) => {
                    return (
                        dimensions.width > 1600 ? <>
                            <p className='text'>{cell.value}</p></> :
                            <>
                                <p className="text">
                                    Station : {cell.value}
                                </p>
                                <p className="text textOverflow">
                                    Connectors : {cell?.row?.original?.nConnectors}
                                </p>
                            </>
                    )
                },
            },
            dimensions?.width > 1600 ? {
                Header: `Connectors (${noOfConnectors ? noOfConnectors : '0'})`,
                accessor: "nConnectors",
                filterable: true,
                disableGlobalFilter: true,
                show: true,
                Cell: (cell) => {
                    return <p className='text textOverflow'>{cell.value ? cell.value : '0'}</p>;
                },
            } : {},
            {
                Header: "Action",
                show: true,
                disableSortBy: true,
                Cell: (item) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0" onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            {/* <li>
                                <div className="form-check form-switch">
                                    <Input
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id={item.row.original._id}
                                        checked={item.row.original.checked}
                                        onChange={() => {
                                            
                                            if (item.row.original.checked === true) {
                                                setDisabledModal(true);
                                            } else {
                                                setEnabledModal(true);
                                            }
                                            setLocationData(item.row.original);
                                        }
                                        }


                                    />
                                </div>
                            </li> */}
                            {/* {getUserRole() !== 'LM' && <li className="list-inline-item"> */}
                            {window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined && JSON.parse(window.localStorage.getItem('users')).userRole !== 'LocationManager' && <li className="list-inline-item">
                                {

                                    (item.row.original.isVerified === 0) ?
                                        <CustomButton
                                            disabled={item.row.original.checked === false ? true : false}
                                            title='Resend invite'
                                            type='button'
                                            loading={item.row.id === buttonIndex && loading}
                                            className='resendBtn'
                                            onClick={(e) => {
                                                setButtonIndex(item.row.id)
                                                e.stopPropagation();
                                                dispatch(resendLocationInvite({ locationId: item.row.original.locationId }));
                                            }}
                                        />
                                        : '-'
                                }
                            </li>}
                            {item.row.original.MANAGER && <li className="list-inline-item edit">
                                <Button
                                    disabled={item.row.original.checked === false ? true : false}
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push('/location/editlocation');
                                    }}
                                >
                                    <i className="bx bx-edit-alt editBtn"></i>
                                </Button>
                            </li>
                            }
                            {/* <li className="list-inline-item">
                                <Button
                                    // disabled={item.row.original.checked === false ? true : false}
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteModal(true);
                                    }}
                                >
                                    <i className="bx bx-trash deleteBtn "></i>
                                </Button>
                            </li> */}
                        </ul>
                    );
                },
            }
        ],
        [history, selectValue, noOfConnectors, noOfStation, handleBusinesNameSort, handleLocationNameSort, loading]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {IsShowMap === true && <BreadCrumb
                        className='breadCrumb'
                        onClickBack={(e) => {
                            // history.push('/location');
                            // history.goBack();
                            e.preventDefault();
                            setIsShowMap(false);
                        }}
                        isButton={true}
                        title="Location Name -1"
                        OnClickpageTitle={(e) => { e.preventDefault(); setIsShowMap(false); }}
                        pageTitle="Location"

                    />}
                    <div className='LocationListingPage' style={{ height: IsShowMap === true ? dimensions.height - headerHeight - footerHeight - breadCrumb : dimensions.height - headerHeight - footerHeight }}>
                        <div className='mapButtons'>
                            <div className='d-flex justify-content-center align-items-center flex-column'>
                                {/* <Button className='btnOne mb-3'>
                                    <i className='mdi mdi-crosshairs-gps fs-22' />
                                </Button> */}
                                <Button className={IsShowMap ? 'btnWhite' : 'btnOne '} onClick={() => {
                                    setIsShowMap(!IsShowMap);
                                }}>
                                    {
                                        IsShowMap
                                            ? <img src='https://res.cloudinary.com/djyl1goby/image/upload/v1670217891/Lubi/WebApp/Static-images/Filter.svg' alt='' />
                                            : <img src='https://res.cloudinary.com/https-www-nimblechapps-com/image/upload/v1688013507/Lubi/WebApp/Static-images/map_switch.svg' alt='' />
                                    }
                                </Button>
                            </div>
                        </div>
                        <Row>
                            <Col lg={12}>
                                <Card className='m-0'>
                                    {IsShowMap
                                        ? <>
                                            <CardHeader className='cardHeader'>
                                                <p className='headerTxt'>Locations</p>
                                            </CardHeader>
                                            <CardBody>
                                                <div className='mapContainer' style={{ height: dimensions.height - headerHeight - breadCrumb - cardHeader - footerHeight - 80 }}>
                                                    {isLoaded && <GoogleMap
                                                        id="searchbox-example"
                                                        mapContainerStyle={{ width: '100%', height: '100%' }}
                                                        center={center}
                                                        zoom={12}
                                                        options={{
                                                            streetViewControl: false,
                                                            mapTypeControl: false,
                                                            zoomControl: false,
                                                            fullscreenControl: false
                                                        }}
                                                    >
                                                        <>
                                                            {mapLocationEntry?.map((item, index) => {
                                                                return (
                                                                    <Marker
                                                                        key={index}
                                                                        icon={''}
                                                                        position={item.center}
                                                                        onClick={() => { }}
                                                                    >
                                                                        <InfoBox
                                                                            position={item.center}
                                                                            className={''}
                                                                        >
                                                                            <div className='customMarker'>
                                                                                {/* <img className='sPin' src='https://res.cloudinary.com/djyl1goby/image/upload/v1669786063/Lubi/WebApp/Static-images/stationPin.svg' alt='' /> */}
                                                                                <i className='sPin icon icon-pin' />
                                                                                <div className='graph'>
                                                                                    <ChangingProgressProvider values={[item.value]}>
                                                                                        {(value) => (
                                                                                            <CircularProgressbar
                                                                                                value={value / 0.10}
                                                                                                text={`${value}`}
                                                                                                circleRatio={0.75}
                                                                                                styles={buildStyles({
                                                                                                    rotation: 1 / 2 + 1 / 8,
                                                                                                    strokeLinecap: "round",
                                                                                                    textColor: "#fff",
                                                                                                    pathColor: "#040404",
                                                                                                    trailColor: "#fff",
                                                                                                    textSize: '42px',
                                                                                                })} />
                                                                                        )}
                                                                                    </ChangingProgressProvider>
                                                                                </div>
                                                                            </div>
                                                                        </InfoBox>
                                                                    </Marker>
                                                                );
                                                            })}
                                                        </>
                                                    </GoogleMap>}
                                                </div>
                                            </CardBody>
                                        </>
                                        : <>
                                            {/* {getUserRole() === 'LM' && */}
                                            {(window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined) &&
                                                JSON?.parse(window.localStorage.getItem('users'))?.userRole == 'LocationManager' &&

                                                <Row className='locationManager locationManagerHeight'>
                                                    <p className='headerTxt'>{BusinessDetail?.businessName}</p>
                                                    <Row className='dStrucutre'>
                                                        <div className='dLeft'>
                                                            <div className='dBox imageBox'>
                                                                <div className='logoMain'>
                                                                    <div className='outLine d-flex align-items-center justify-content-center mb-2'>
                                                                        {BusinessDetail?.businessLogo
                                                                            ? <img
                                                                                src={process.env.REACT_APP_CLOUDINARY_URL + BusinessDetail?.businessLogo}
                                                                                alt=''

                                                                            />
                                                                            : <span style={{ color: '#fff', fontSize: '20px' }}>{BusinessDetail?.businessName?.toUpperCase().match(/\b\w/g).join('')}</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='innerDiv'>
                                                                <div className='dBox'>
                                                                    <div className='content'>
                                                                        <p className='title  mb-2'>Business ID</p>
                                                                        <p className='txt1 textOverflow mb-1'>{BusinessDetail?.prefix + BusinessDetail?.businessId}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='dBox'>
                                                                    <div className='content'>
                                                                        <p className='title mb-2'>Business owner name</p>
                                                                        <p className='txt1 textOverflow mb-1'>{getValueFromObject('firstName', BusinessDetail, '')} {getValueFromObject('lastName', BusinessDetail, '')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='dBox'>
                                                                    <div className='content'>
                                                                        <p className='title  mb-2'>Email address</p>
                                                                        <p className='txt1  mb-1'>{getValueFromObject('email', BusinessDetail, '')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='dBox'>
                                                                    <div className='content'>
                                                                        <p className='title  mb-2'>Phone number</p>
                                                                        <p className='txt1  mb-1'>{getValueFromObject('contactNumber', BusinessDetail, '')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='dBox'>
                                                                    <div className='content'>
                                                                        <p className='title  mb-2'>Website</p>
                                                                        <p className='txt1 mb-1'>{BusinessDetail?.website ? BusinessDetail?.website : '-'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='dBox'>
                                                                    <div className='box d-flex align-items-start justify-content-center flex-column'>
                                                                        <p className='themeTxt mb-2'>Theme</p>
                                                                        <div className='themeBox'>
                                                                            <div className='round'><span style={{ backgroundColor: BusinessDetail?.primaryColor ? BusinessDetail?.primaryColor : '-' }}></span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <hr />
                                                </Row>
                                            }
                                            <CardHeader className='cardHeader'>
                                                <p className='headerTxt'>Locations</p>
                                            </CardHeader>
                                            <CardBody>
                                                <div className={filteredTable.length > 0 && locationListData?.length > 0 ? 'table-content' : 'table-content disablePointer'}>
                                                    {locationLoader ?
                                                        <TableContainer
                                                            columns={columns1.filter((a) => a.show)}
                                                            userRole={window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined && JSON.parse(window.localStorage.getItem('users')).userRole == 'LocationManager' ? true : false}
                                                            data={locationListData}
                                                            isGlobalFilter={true}
                                                            customPageSize={15}
                                                            setfilteredTable={setfilteredTable}
                                                            isDropdown={window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined && JSON.parse(window.localStorage.getItem('users')).userRole !== ('LocationManager' || 'BusinessOwner') ? false : true}
                                                            dropDownItems={businessName.sort((a, b) => { return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1 })}
                                                            onSelect={(evt) => {
                                                                setSelectValue(evt);

                                                                if (evt.value === 'All') {
                                                                    setShowNoData(false)
                                                                    // setLocationListData(locationList)
                                                                    dispatch(allLocation('?id='));
                                                                } else {
                                                                    dispatch(allLocation(`?id=${evt?.BusinessID}`));
                                                                    if (locationList?.length > 0) {
                                                                        setShowNoData(false);
                                                                        setLocationListData(locationList)
                                                                    } else {
                                                                        setShowNoData(true);
                                                                    }
                                                                    // const filterArray = locationList.filter((e) => e.businessName === evt.value);
                                                                    // if (filterArray?.length > 0) {
                                                                    //     setShowNoData(false);
                                                                    //     setLocationListData(filterArray);
                                                                    // } else {
                                                                    //     setShowNoData(true);
                                                                    // }

                                                                }
                                                            }}
                                                            selectedValue={selectValue}
                                                            handleViewDetailClick={(row) => handleViewDetailClick(row)}
                                                            dropdownTitle='Business'
                                                            // tableHeight='500px'
                                                            divClass="table-responsive mb-1 locationTable"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="table-light text-muted"
                                                            // isAddButton={getUserRole() === 'LM' ? false : true}
                                                            isAddButton={window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined && JSON.parse(window.localStorage.getItem('users')).userRole == 'LocationManager' ? false : true}
                                                            //isAddButtonDisabled={selectValue?.value === 'All' ? true : selectValue ? false : true}
                                                            isAddButtonDisabled={false}
                                                            searchPlaceholder='Search Location Name, Region, Business Name, Location Manager'
                                                            handleAddButtonClicks={handleAddButtonClicks}
                                                            addButtonTitle='Add Location'
                                                            noData={locationList?.length > 0 ? false : true}
                                                            noDataImage={"https://res.cloudinary.com/djyl1goby/image/upload/v1670411148/Lubi/WebApp/Static-images/No_Location_found.svg"}
                                                            noDataTitle={'No Locations Found!'}
                                                        /> : <ContentLoader />}
                                                </div>
                                            </CardBody>
                                        </>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            {/* <DeleteModal
                show={deleteModal}
                title='Are you sure?'
                description='Are you sure you want to delete this record?'
                onDeleteClick={handleDeleteContact}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DisableModal
                show={disabledModal}
                title='Are you sure?'
                subtitle='Are you sure you want to disable this record?'
                btnActionText='Yes, Disable it!'
                btncancelText='Cancel'
                onActionClick={handleDisableContact}
                onCloseClick={() => setDisabledModal(false)}
            />
            <DisableModal
                show={enabledModal}
                title='Are you sure?'
                subtitle='Are you sure you want to enable this record?'
                btnActionText='Yes, Enable it!'
                btncancelText='Cancel'
                onActionClick={handleEnableContact}
                onCloseClick={() => setEnabledModal(false)}
            /> */}
        </React.Fragment>
    );
};

export default Location;