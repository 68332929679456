import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import Constant from "../../../utils/constant";
import { FieldArray, Formik } from "formik";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useHistory, useLocation } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addTariff, allBusinessList, detailViewTariff, editTariff } from "../../../store/actions";
import CustomDropdown from "../../../Components/Common/CustomDropdown";
import CustomButton from "../../../Components/Common/CustomButton";
import { formatValues, getUserRole } from "../../../helpers/helper.service";
import ContentLoader from "../../../Components/Common/ContentLoader";


const AddTariff = (props) => {
  const DefaultVariableData = {
    fromTime: null,
    toTime: null,
    KWPrice: "",
    minutePrice: "",
    day: [
      {
        id: 'Mon',
        dayCheck: false
      },
      {
        id: 'Tue',
        dayCheck: false
      },
      {
        id: 'Wed',
        dayCheck: false
      },
      {
        id: 'Thu',
        dayCheck: false
      },
      {
        id: 'Fri',
        dayCheck: false
      },
      {
        id: 'Sat',
        dayCheck: false
      },
      {
        id: 'Sun',
        dayCheck: false
      }
    ]
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, state } = useLocation();
  const [businessName, setBusinessName] = useState(false);
  const [validBusiness, setValidBusiness] = useState([]);
  const [businessID, setBusinessID] = useState('');
  const [OPtype, setOPtype] = useState('');
  const [traiffsID, setTraiffsID] = useState('');
  const [editBUSName, setEditBUSName] = useState(false);
  const [variableTariffList, setVariableTariffList] = useState([DefaultVariableData]);

  const { tariffDetails, businessList, loading, tariffVariableDetails } = useSelector(state => ({
    businessList: state?.businessList?.allBusinessList,
    tariffDetails: OPtype === 'Edit' ? state?.ManageTariffs?.DetailTariff[0] : {},
    tariffVariableDetails: OPtype === 'Edit' ? state?.ManageTariffs?.DetailTariff[0]?.variableTariff : [],
    loading: state.Toast.loader
  }));

  useEffect(() => {
    dispatch(allBusinessList('?id='));
    if (state?.state?.type === 'Edit') {
      setOPtype(state?.state?.type);
      setTraiffsID(state?.state?.tariffID);
      dispatch(detailViewTariff(state?.state?.tariffID));
    }
  }, []);

  // redirect back to listing if no id is found in case of manual copying url from previos tab
  useLayoutEffect(() => {
    if (state == undefined && window.location.href.includes('edit')) {
      history.push('/tariff');
    }
    if (getUserRole() === 'LM') {
      setBusinessID(state?.state?.businessID)
    }
  }, [])

  useEffect(() => {
    if (businessList?.length > 0) {
      const BusinessName = businessList?.map((e, i) => {
        return {
          id: `${e.businessId}`,
          value: e.businessName
        }
      })
      setValidBusiness(BusinessName);
    }
    businessList?.length === 1 && setBusinessID(businessList[0]?.businessId)
  }, [businessList]);

  useEffect(() => {
    if (state?.state?.type === 'Edit') {
      const BName = businessList?.filter(e => e.businessId === tariffDetails?.businessId).map(e => e.businessName);
      const editBUSValue = {
        id: `${tariffDetails?.businessId}`,
        value: BName.toString()
      }

      setEditBUSName(editBUSValue);
      setBusinessID(tariffDetails?.businessId);
    }
  }, [businessList, tariffDetails]);

  useEffect(() => {
    if (tariffVariableDetails?.length > 0) {
      let data = [...tariffVariableDetails]
      data = data.map(item => ({ ...item, toTime: new Date(item.toTime), fromTime: new Date(item.fromTime) }))
      console.log(data);
      setVariableTariffList(data)
    }
    if (pathname === "/tariff/addtariff") {
      setVariableTariffList([DefaultVariableData])
    }
  }, [tariffVariableDetails?.length, pathname])

  // avoid any input other than numbers, decimal point and backspace
  function checkForValidNumber(e) {
    if (!(/[0-9\b\.]/.test(e.key))) {
      e.preventDefault();
      return;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            onClickBack={(e) => {
              e.preventDefault();
              history.goBack();
            }}
            isButton={true}
            title={pathname === "/tariff/addtariff" ? "Add Tariff" : "Edit Tariff"}
            pageTitle="Tariff"
            OnClickpageTitle={(e) => { e.preventDefault(); history.goBack(); }}
          />
          <div className="addTariffPage">
            <Row>
              <Col lg={12} xl={12}>
                {loading ? <ContentLoader /> :
                  <Formik
                    initialValues={{
                      tariffName: (tariffDetails && tariffDetails?.tariffName) || "",
                      pricPriceperkW: (tariffDetails && tariffDetails?.KWPrice) || "",
                      minutePrice: (tariffDetails && tariffDetails?.minutePrice) || "",
                      Reservationcharge: (tariffDetails && tariffDetails?.rCharges) || "",
                      Cancellationcharges: (tariffDetails && tariffDetails?.cCharges) || "",
                      // businessName: (tariffDetails && tariffDetails?.businessId) || ''editBUSName,
                      businessName: editBUSName?.value || '',
                      variablePriceData: variableTariffList

                    }}
                    validationSchema={
                      Yup.object({
                        tariffName: Yup.string()
                          .required("Tariff name is required")
                          .matches(Constant.REGEX.ALPHANUMERIC, "Alphanumeric characters only"),
                        pricPriceperkW: Yup.string()
                          .required("Price per kW is required")
                          .test('ss', 'Price per kW is invalid', (value) => {
                            return (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value);
                          })
                          .test('s', 'Price per kW must be below 1000', (value) => Number(value) <= 1000),
                        minutePrice: Yup.string()
                          .required("Price per Minute is required")
                          .test('ss', 'Price per Minute is invalid', (value) => {
                            return (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value);
                          })
                          .test('s', 'Price per Minute must be below 1000', (value) => Number(value) <= 1000),
                        Reservationcharge: Yup.string()
                          .required("Reservation charge is required")
                          .test('ss', 'Reservation charge is invalid', (value) => {
                            return (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value);
                          })
                          .test('s', 'Reservation charge must be below 1000', (value) => Number(value) <= 1000),
                        Cancellationcharges: Yup.string()
                          .required("Cancellation charge is required")
                          .test('ss', 'Cancellation charge is invalid', (value) => {
                            return (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value);
                          })
                          .test('s', 'Cancellation charge must be below 1000', (value) => Number(value) <= 1000),
                        businessName: window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner' ? Yup.string() : validBusiness.length === 1 ? Yup.string() : Yup.string().required(),

                        variablePriceData: Yup.array().of(
                          Yup.object().shape({
                            fromTime: Yup.date().nullable(),
                            toTime: Yup.date().nullable(),
                            KWPrice: Yup.string()
                              .test('required-if-any', 'Price per kW is required', function (value) {
                                const { fromTime, toTime, minutePrice } = this.parent;
                                if (fromTime || toTime || minutePrice || value) {
                                  return value && (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value) && Number(value) <= 1000;
                                }
                                return true;
                              }),
                            minutePrice: Yup.string()
                              .test('required-if-any', 'Price per Minute is required', function (value) {
                                const { fromTime, toTime, KWPrice } = this.parent;
                                if (fromTime || toTime || KWPrice || value) {
                                  return value && (/^(\d)+\.(\d)+$|^(\d)+$/gm).test(value) && Number(value) <= 1000;
                                }
                                return true;
                              }),
                          })
                        ).notRequired(), // Makes the array itself optional
                      })
                    }
                    onSubmit={(values, { resetForm }) => {
                      const params = {
                        tariffName: values?.tariffName,
                        KWPrice: +(values?.pricPriceperkW),
                        minutePrice: (values?.minutePrice),
                        rCharges: +(values?.Reservationcharge),
                        cCharges: +(values?.Cancellationcharges),
                        businessId: +(businessID),
                        variableTariff: values?.variablePriceData
                      }
                      if (OPtype === 'Edit') {
                        console.log("edit params ==>", params);
                        dispatch(editTariff(params, traiffsID, props.history));
                      } else {
                        console.log("add params ==>", params);
                        dispatch(addTariff(params, props.history))
                      }
                      resetForm()
                    }}
                  >
                    {(formikProps) => (
                      <Form>
                        <Card className="mb-0">
                          <CardHeader>
                            <p className="headerTxt">{pathname === "/tariff/addtariff" ? 'Add Tariff' : 'Edit Traiff'}</p>
                          </CardHeader>
                          <CardBody>
                            <div className="main">
                              <Row>
                                <div className="subMian col-sm-5">
                                  <Label
                                    htmlFor="tariffName"
                                    className="form-label requierdLabel"
                                  >
                                    Name
                                  </Label>
                                  <Input
                                    name="tariffName"
                                    type="text"
                                    className="form-control"
                                    id="tariffName"
                                    maxLength={50}
                                    placeholder="Enter Tariff Name"
                                    onChange={formikProps.handleChange}
                                    onKeyUp={formikProps.handleBlur}
                                    value={formikProps.values.tariffName.trimStart() || ""}
                                    invalid={
                                      formikProps.errors.tariffName &&
                                        formikProps.touched.tariffName
                                        ? true
                                        : false
                                    }
                                  />
                                  {formikProps.errors.tariffName &&
                                    formikProps.touched.tariffName ? (
                                    <FormFeedback type="invalid">
                                      {formikProps.errors.tariffName}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                {getUserRole() !== 'LM' ?
                                  <div className="subMian col-sm-5">
                                    <Label
                                      htmlFor="tariffName"
                                      className="form-label requierdLabel"
                                    >
                                      Businesses
                                    </Label>
                                    <CustomDropdown
                                      defaultValue={validBusiness.length === 1 ? validBusiness[0]?.value : ''}
                                      className={validBusiness.length === 1 ? 'read_OnlyDropdown' : ''}
                                      disabled={validBusiness.length === 1 ? true : false}
                                      selectedValue={businessName || editBUSName}
                                      dropDownItems={validBusiness}
                                      placeholder={'Select business name'}
                                      onSelect={(evt) => {
                                        setBusinessName(evt);
                                        setBusinessID(evt?.id);
                                        formikProps.handleChange('businessName')(evt.value);
                                      }}
                                    />
                                  </div>
                                  : null}

                              </Row>
                              <hr />
                              <div className="row">
                                <div className="col-sm-5">
                                  <div className="subMian mb-3 col-sm-12">
                                    <Label className="form-label requierdLabel">
                                      Flat Price
                                    </Label>
                                    <Label
                                      htmlFor="pricPriceperkW"
                                      className="small-form-label requierdLabel"
                                    >
                                      Price per kw
                                    </Label>
                                    <Input
                                      name="pricPriceperkW"
                                      type="text"
                                      className="form-control"
                                      id="pricPriceperkW"
                                      placeholder="₹ 0.00"
                                      onKeyUp={formikProps.handleBlur}
                                      maxLength={8}
                                      onChange={formikProps.handleChange}
                                      value={
                                        // formikProps.values.pricPriceperkW || ""
                                        formikProps.values.pricPriceperkW
                                      }
                                      invalid={
                                        formikProps.errors.pricPriceperkW &&
                                          formikProps.touched.pricPriceperkW
                                          ? true
                                          : false
                                      }
                                      onKeyDown={(e) => {
                                        // if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66 || e.key.charCodeAt() === 46)) {
                                        //   e.preventDefault();
                                        // }
                                        if (e.key.charCodeAt() === 32) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onKeyPress={checkForValidNumber}
                                    />
                                    {formikProps.errors.pricPriceperkW &&
                                      formikProps.touched.pricPriceperkW ? (
                                      <FormFeedback type="invalid">
                                        {formikProps.errors.pricPriceperkW}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="subMian  col-sm-12">
                                    <Label
                                      htmlFor="minutePrice"
                                      className="small-form-label requierdLabel"
                                    >
                                      Price per Minute
                                    </Label>
                                    <Input
                                      name="minutePrice"
                                      type="text"
                                      className="form-control"
                                      id="minutePrice"
                                      placeholder="₹ 0.00"
                                      maxLength={8}
                                      onChange={formikProps.handleChange}
                                      onKeyUp={formikProps.handleBlur}
                                      value={
                                        formikProps.values.minutePrice
                                      }
                                      invalid={
                                        formikProps.errors.minutePrice &&
                                          formikProps.touched.minutePrice
                                          ? true
                                          : false
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key.charCodeAt() === 32) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onKeyPress={checkForValidNumber}
                                    />
                                    {formikProps.errors.minutePrice &&
                                      formikProps.touched.minutePrice ? (
                                      <FormFeedback type="invalid">
                                        {formikProps.errors.minutePrice}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-sm-5">
                                  <div className="subMian mb-3 col-sm-12">
                                    <Label className="form-label requierdLabel">
                                      Other Charges
                                    </Label>
                                    <Label
                                      htmlFor="Reservationcharge"
                                      className="small-form-label requierdLabel"
                                    >
                                      Reservation charge
                                    </Label>
                                    <Input
                                      name="Reservationcharge"
                                      type="text"
                                      className="form-control"
                                      maxLength={8}
                                      id="Reservationcharge"
                                      placeholder="₹ 0.00"
                                      onChange={formikProps.handleChange}
                                      onKeyUp={formikProps.handleBlur}
                                      value={
                                        // formikProps.values.Reservationcharge || ""
                                        formikProps.values.Reservationcharge
                                      }
                                      invalid={
                                        formikProps.errors.Reservationcharge &&
                                          formikProps.touched.Reservationcharge
                                          ? true
                                          : false
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key.charCodeAt() === 32) {
                                          e.preventDefault();
                                        }
                                        // if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66 || e.key.charCodeAt() === 46)) {
                                        //   e.preventDefault();
                                        // }
                                      }}
                                      onKeyPress={checkForValidNumber}
                                    />
                                    {formikProps.errors.Reservationcharge &&
                                      formikProps.touched.Reservationcharge ? (
                                      <FormFeedback type="invalid">
                                        {formikProps.errors.Reservationcharge}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="subMian  col-sm-12">
                                    <Label
                                      htmlFor="Cancellationcharges"
                                      className="small-form-label requierdLabel"
                                    >
                                      Cancellation charge
                                    </Label>
                                    <Input
                                      name="Cancellationcharges"
                                      type="text"
                                      className="form-control"
                                      id="Cancellationcharges"
                                      placeholder="₹ 0.00"
                                      maxLength={8}
                                      onChange={formikProps.handleChange}
                                      onKeyUp={formikProps.handleBlur}
                                      value={
                                        // formikProps.values.Cancellationcharges || ""
                                        formikProps.values.Cancellationcharges
                                      }
                                      invalid={
                                        formikProps.errors.Cancellationcharges &&
                                          formikProps.touched.Cancellationcharges
                                          ? true
                                          : false
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key.charCodeAt() === 32) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onKeyPress={checkForValidNumber}
                                    />
                                    {formikProps.errors.Cancellationcharges &&
                                      formikProps.touched.Cancellationcharges ? (
                                      <FormFeedback type="invalid">
                                        {formikProps.errors.Cancellationcharges}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <Label htmlFor="tariffName" className="form-label">
                                Variable Price
                              </Label>
                              <div className="Bottom-Table">
                                <div className="Table-header">
                                  <ul className="Table-row">
                                    <li className="Table-name">
                                      <Label className="form-label">
                                        From
                                      </Label>
                                    </li>
                                    <li className="Table-name">
                                      <Label className="form-label">
                                        To
                                      </Label>
                                    </li>
                                    <li className="Table-name">
                                      <Label className="form-label">
                                        Price per kW
                                      </Label>
                                    </li>
                                    <li className="Table-name">
                                      <Label className="form-label ">
                                        Price per Minute
                                      </Label>
                                    </li>
                                    <li className="Table-name last-table">
                                      <ul className="Date-Table-name">
                                        <li className="Table-name">
                                          <Label className="form-label">Mon</Label>
                                        </li>
                                        <li className="Table-name">
                                          <Label className="form-label ">Tue</Label>
                                        </li>
                                        <li className="Table-name">
                                          <Label className="form-label ">Wed</Label>
                                        </li>
                                        <li className="Table-name">
                                          <Label className="form-label ">Thu</Label>
                                        </li>
                                        <li className="Table-name">
                                          <Label className="form-label ">Fri</Label>
                                        </li>
                                        <li className="Table-name">
                                          <Label className="form-label ">Sat</Label>
                                        </li>
                                        <li className="Table-name">
                                          <Label className="form-label ">Sun</Label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                  <FieldArray name="variablePriceData">
                                    {({ push, remove }) => (
                                      <>
                                        <div>
                                          {formikProps?.values?.variablePriceData?.map((item, index) => (
                                            <>
                                              <ul className="Table-Body" key={index}>
                                                <div
                                                  onClick={() => remove(index)}
                                                  className="text-danger d-inline-block remove-item-btn removeAddTriff">
                                                  <i className="bx bx-minus-circle "></i>
                                                </div>
                                                <li className="Table-name">
                                                  <div className="timerDiv">
                                                    <ReactDatePicker
                                                      placeholderText="--:-- --"
                                                      className={`simpleTimePicker ${formikProps?.errors?.variablePriceData?.[index]?.fromTime && formikProps?.touched?.variablePriceData?.[index]?.fromTime ? 'error' : ''}`}
                                                      selected={item.fromTime}
                                                      onBlur={() => {
                                                        formikProps.setFieldTouched(`variablePriceData.${index}.fromTime`, true);
                                                      }}

                                                      onChange={(date) => {
                                                        formikProps.setFieldValue(`variablePriceData.${index}.fromTime`, date);
                                                        formikProps.setFieldValue(
                                                          `variablePriceData.${index}.toTime`,
                                                          null
                                                        );
                                                      }}
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      name={`variablePriceData.${index}.fromTime`}
                                                      timeIntervals={30}
                                                      timeCaption="Time"
                                                      dateFormat="h:mm aa"
                                                    />
                                                    <i className="mdi mdi-clock-time-four-outline fs-16"></i>
                                                  </div>
                                                  {formikProps?.errors?.variablePriceData?.[index]?.fromTime &&
                                                    formikProps?.touched?.variablePriceData?.[index]?.fromTime && (
                                                      <p className="error">From Time is required</p>
                                                    )}
                                                </li>
                                                <li className="Table-name">
                                                  <div className="timerDiv">
                                                    <ReactDatePicker
                                                      placeholderText="--:-- --"
                                                      className={`simpleTimePicker ${formikProps?.errors?.variablePriceData?.[index]?.toTime && formikProps?.touched?.variablePriceData?.[index]?.toTime
                                                        ? 'error'
                                                        : ''}`}
                                                      selected={item.toTime}
                                                      name={`variablePriceData.${index}.toTime`}
                                                      onBlur={() => {
                                                        formikProps.setFieldTouched(`variablePriceData.${index}.toTime`, true);
                                                      }}
                                                      onChange={(date) => {
                                                        formikProps.setFieldValue(`variablePriceData.${index}.toTime`, date);
                                                      }}
                                                      showTimeSelect
                                                      minTime={
                                                        item.fromTime
                                                          ? new Date(moment(item.fromTime).add(30, "minutes").format())
                                                          : setHours(setMinutes(new Date(), 0), 0)
                                                      }
                                                      maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                                      showTimeSelectOnly
                                                      timeIntervals={30}
                                                      timeCaption="Time"
                                                      dateFormat="h:mm aa"
                                                    />
                                                    <i className="mdi mdi-clock-time-four-outline fs-16"></i>
                                                  </div>
                                                  {formikProps?.errors?.variablePriceData?.[index]?.toTime &&
                                                    formikProps?.touched?.variablePriceData?.[index]?.toTime && (
                                                      <p className="error">To Time is required</p>
                                                    )}
                                                </li>
                                                <li className="Table-name">
                                                  <Input
                                                    name={`variablePriceData.${index}.KWPrice`}
                                                    type="text"
                                                    className="form-control"
                                                    id={item.id}
                                                    placeholder="₹ 0.00"
                                                    onKeyUp={formikProps.handleBlur}
                                                    value={formatValues(item?.KWPrice)}
                                                    onChange={(e) => {
                                                      formikProps.setFieldValue(`variablePriceData[${index}].KWPrice`, e.target.value);
                                                    }}
                                                    invalid={
                                                      formikProps?.errors?.variablePriceData?.[index]?.KWPrice &&
                                                        formikProps?.touched?.variablePriceData?.[index]?.KWPrice
                                                        ? true
                                                        : false
                                                    }
                                                    maxLength={8}
                                                    onKeyDown={(e) => {
                                                      if (e.key.charCodeAt() === 32) {
                                                        e.preventDefault();
                                                      }
                                                      // if (!((e.key.charCodeAt() > 47 && e.key.charCodeAt() < 58) || e.key.charCodeAt() === 66 || e.key.charCodeAt() === 46)) {
                                                      //   e.preventDefault();
                                                      // }
                                                    }}
                                                    onKeyPress={checkForValidNumber}
                                                  />
                                                  {
                                                    formikProps?.errors?.variablePriceData?.[index]?.KWPrice &&
                                                      formikProps?.touched?.variablePriceData?.[index]?.KWPrice ? (
                                                      <FormFeedback type="invalid">
                                                        {formikProps.errors.variablePriceData[index].KWPrice}
                                                      </FormFeedback>
                                                    ) : null}
                                                </li>
                                                <li className="Table-name">
                                                  <Input
                                                    name={`variablePriceData.${index}.minutePrice`}
                                                    type="text"
                                                    className="form-control"
                                                    id={item.id}
                                                    placeholder="₹ 0.00"
                                                    onKeyUp={formikProps.handleBlur}
                                                    value={formatValues(item?.minutePrice)}
                                                    onChange={(e) => {
                                                      formikProps.setFieldValue(`variablePriceData[${index}].minutePrice`, e.target.value);
                                                    }}
                                                    invalid={
                                                      formikProps?.errors?.variablePriceData?.[index]?.minutePrice &&
                                                        formikProps?.touched?.variablePriceData?.[index]?.minutePrice
                                                        ? true
                                                        : false
                                                    }
                                                    maxLength={8}
                                                    onKeyDown={(e) => {
                                                      if (e.key.charCodeAt() === 32) {
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                    onKeyPress={checkForValidNumber}
                                                  />
                                                  {formikProps?.errors?.variablePriceData?.[index]?.minutePrice &&
                                                    formikProps?.touched?.variablePriceData?.[index]?.minutePrice ? (
                                                    <FormFeedback type="invalid">
                                                      {formikProps.errors.variablePriceData[index].minutePrice}
                                                    </FormFeedback>
                                                  ) : null}
                                                </li>
                                                <li className="Table-name last-table">
                                                  <ul className="Date-Table-name">
                                                    {item?.day?.map((value, i) => {
                                                      return (
                                                        <li className="Table-name" key={i}>
                                                          <div className="form-check form-check-outline form-check-secondary">
                                                            <Input
                                                              className='form-check-input form-check-input'
                                                              type="checkbox"
                                                              id={value.id}
                                                              name={`variablePriceData.${index}.day[${i}].dayCheck`}
                                                              checked={value.dayCheck}
                                                              onChange={(e) =>
                                                                formikProps.setFieldValue(`variablePriceData.${index}.day[${i}].dayCheck`, e.target.checked)
                                                              }
                                                            />
                                                          </div>
                                                        </li>
                                                      );
                                                    })}

                                                  </ul>
                                                </li>
                                              </ul>
                                            </>
                                          ))}
                                        </div>
                                        <div className="BottomFormBtn">
                                          <Button color="success" className="btn btn-add-more" onClick={() => push(DefaultVariableData)}>
                                            Add more
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </FieldArray>
                                </div>
                              </div>
                            </div>
                          </CardBody>

                        </Card>
                        <div className="Form-Bottom-button">
                          <CustomButton
                            title={pathname === "/tariff/addtariff" ? 'Add Tariff' : 'Update'}
                            disabled={!(formikProps.dirty && formikProps.isValid)}
                            type='button'
                            loading={loading}
                            className='btn addButtonWithLoader btn-form btn-left'
                            btnIcon={pathname === '/tariff/edittariff' ? '' : "ri-add-line align-middle fs-16 me-2"}
                            onClick={formikProps.submitForm}
                          />
                          <Button type="button" className="btn btn-form cancelBtn btn-soft btn btn-secondary" onClick={() => {
                            history.goBack();
                          }} >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                }
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddTariff;