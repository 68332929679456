import { call, put, takeEvery } from "redux-saga/effects";
// Login Redux States
import { LOGIN, LOGOUT_USER, SOCIAL_LOGIN, REFRESH_TOKEN } from "./actionTypes";
// import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { apiError, loginSuccess, logoutUserSuccess, showToast, showLoader } from "../../actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { Login, RefreshToken } from "../../../helpers/Auth";
import socket from '../../../socketConfig';

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  console.log(user, history);
  try {
    yield put(showLoader(true));
    const response = yield call(Login, {
      email: user.email,
      password: user.password,
    });

    if (response.status === 200) {
      yield put(loginSuccess('someting'));
      sessionStorage.setItem("token", JSON.stringify(response.payload[1]));
      sessionStorage.setItem("users", JSON.stringify(response.payload[0]));
      window.localStorage.setItem("users", JSON.stringify(response.payload[0]));
      window.localStorage.setItem("token", JSON.stringify(response.payload[1]));
      socket.emit('joinRoom', `user_${response.payload[0].userId}`);
      document.getElementById('12').setAttribute("data-theme", response.payload[0].primaryColor);
      yield put(showToast({
        message: response.message, //'Logged in successfully.'
        type: 'success'
      }));
      // setTimeout(() => {
      window.localStorage.setItem('islogged', true);
      history.push('/dashboard')
      yield put(showLoader(false));
      // }, 1000);
    }
  } catch (e) {
    console.log(e);
    yield put(showToast({
      message: e?.response?.data?.message,// e.message, 'Logged failed.'
      type: 'error'
    }));
    yield put(showLoader(false));
    // yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem('users')
    sessionStorage.removeItem('token')
    // sessionStorage.clear();

    yield put(logoutUserSuccess());

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout);
    //   yield put(logoutUserSuccess(LOGOUT_USER, response));
    // } else {
    //   yield put(logoutUserSuccess(LOGOUT_USER, true));
    // }
  } catch (error) {
    // yield put(apiError(LOGOUT_USER, error));
    console.log(error)
  }
}

function* refreshToken({ payload: { user } }) {
  try {
    const response = yield call(RefreshToken, {
      refreshToken: user
    });
    if (response) {
      showToast({
        message: response.message, //'Logged in successfully.'
        type: 'success'
      });
    }
  } catch (e) {
    console.log('awdawdawdawdawd');
    console.log('Error', e)
    yield put(showToast({
      message: e?.response?.data?.message,// e.message, 'Logged failed.'
      type: 'error'
    }));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  // yield takeEvery(REFRESH_TOKEN, refreshToken);
}

export default authSaga;
