
// import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Constant from '../../../../utils/constant';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row, Table } from 'reactstrap';


//import Components
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { FieldArray, useFormik, Field, FormikProvider } from 'formik';
import * as Yup from "yup";
import { showToast } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from "react-google-autocomplete";
import InputMask from 'react-input-mask';
import MapComponent from '../../../../Components/Common/MapComponent';
import ReactDatePicker from 'react-datepicker';
import { setHours, setMinutes } from "date-fns";
import OutsideClickHandler from 'react-outside-click-handler';
import moment from "moment";
import { addNewLocation, LocationDetail, editLocation, getMatchEmail, allLocation, allBusinessList } from '../../../../store/actions';
import { set } from 'react-hook-form';
import CustomButton from '../../../../Components/Common/CustomButton';
import CustomDropdown from '../../../../Components/Common/CustomDropdown';
import { isDateSelectionValid } from '@fullcalendar/react';
import PhoneInput from 'react-phone-input-2'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css'

const AddLocation = (props) => {
    document.title = window.location.href.includes('edit') ? "Edit Location | Vidhyutam" : "Add Location | Vidhyutam";
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname, state } = useLocation();
    const [latlng, setLatLng] = useState();
    const [addressAuto, setAddressAuto] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [changeEmailValue, setChangeEmailValue] = useState(false);
    const [timmingDetail, setTimmingDetail] = useState('')
    const [regionLoc, setRegionLoc] = useState('');
    const [cityLoc, setCityLoc] = useState('');
    const [pinLoc, setPinLoc] = useState('');
    const [typeCheck, setTypeCheck] = useState('');
    const [checkService, setCheckService] = useState(true);
    const [time1, setTime1] = useState(true);
    const [time2, setTime2] = useState(true);
    const [fullcheck, setFullcheck] = useState(false);
    const [read, setRead] = useState(true);
    const [mapAllLocation, setMapAllLocation] = useState([]);
    const [businessName, setBusinessName] = useState([])
    const [allBusinessName, setAllBusinessName] = useState('')
    const [Invalid, setInvalid] = useState(true);
    const [matchingSta, setmatchingSta] = useState(false);
    const [locationValid, setlocationValid] = useState(window.location.href.includes('edit') ? true : false)
    // const [TimeValue1, setTimeValue1] = useState("");
    // const [TimeValue2, setTimeValue2] = useState("");
    const [businessManeger,] = useState(false);
    const [isValid, setIsValid] = useState(null);


    const [SearviceTimingData, setSearviceTimingData] = useState([
        {
            id: '1',
            day: 'Monday',
            dayCheck: false,
            startTime: '',
            endTime: '',
            fullDayCheck: false
        },
        {
            id: '2',
            day: 'Tuesday',
            dayCheck: false,
            startTime: '',
            endTime: '',
            fullDayCheck: false
        },
        {
            id: '3',
            day: 'Wednesday',
            dayCheck: false,
            startTime: '',
            endTime: '',
            fullDayCheck: false
        },
        {
            id: '4',
            day: 'Thursday',
            dayCheck: false,
            startTime: '',
            endTime: '',
            fullDayCheck: false
        },
        {
            id: '5',
            day: 'Friday',
            dayCheck: false,
            startTime: '',
            endTime: '',
            fullDayCheck: false
        },
        {
            id: '6',
            day: 'Saturday',
            dayCheck: false,
            startTime: '',
            endTime: '',
            fullDayCheck: false
        },
        {
            id: '0',
            day: 'Sunday',
            dayCheck: false,
            startTime: '',
            endTime: '',
            fullDayCheck: false
        }
    ]);
    const [fixed, setFixed] = useState(false)

    const [businessID, setBusinessID] = useState(state ? state?.state?.businessID : false)
    const locationId = state?.state?.locationId;
    const businessNAme = state?.state?.businessNAme

    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [phoneTouched, setPhoneTouched] = useState(false)

    const [phoneCode, setPhoneCode] = useState('')
    const [phoneContact, setPhoneContact] = useState('')
    const [fullContactNumber, setFullContactNumber] = useState()



    //TEMPORARY USER ROLE
    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: Constant.GOOGLEMAPKEY.ApiKey
    // })

    useEffect(() => {
        businessID && dispatch(allLocation(`?id=${businessID}`));
    }, [businessID]);

    useEffect(() => {
        dispatch(allBusinessList('?id='));
        if (state?.state?.type === 'LocEdit') {
            dispatch(LocationDetail(locationId));
        } else {
            locationCurrent();
        }
    }, [])

    // redirect back to listing if no id is found in case of manual copying url from previos tab
    useLayoutEffect(() => {
        if (state === undefined && window.location.href.includes('edit')) {
            history.push('/location')
        }
    }, [])

    const { locationDetails, getMatchingEmail, locationList, loading, businessList } = useSelector(state => ({
        locationDetails: typeCheck === 'LocEdit' && state?.locationsList?.LocationDetail[0],
        getMatchingEmail: state?.businessList?.getMatchedEmail?.data?.userDetails,
        locationList: state?.locationsList?.allLocationList,
        businessList: state?.businessList?.allBusinessList,
        loading: state.Toast.loader
    }));


    useEffect(() => {
        if (businessList?.length > 0) {
            const BusinessssName = setBusiness();

            const sortName = BusinessssName?.sort((a, b) => {
                const nameA = a.value?.toUpperCase();
                const nameB = b.value?.toUpperCase();
                if (nameA > nameB) {
                    return 1;
                } else if (nameA < nameB) {
                    return -1;
                } else {
                    return 0;
                }
            })

            setAllBusinessName([...sortName]);

        }
    }, [businessList]);


    //         setAllBusinessName([...BusinessssName].sort((a, b) => { return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1 }));

    // const sortName = businessList?.sort((a, b) => {
    //     const nameA = a.businessName?.toUpperCase();
    //     const nameB = b.businessName?.toUpperCase();
    //     if (nameA > nameB) {
    //         return -1;
    //     } else if (nameA < nameB) {
    //         return 1;
    //     } else {
    //         return 0;
    //     }
    // })


    const setBusiness = () => {
        const tempArray = []
        // const sortName = businessList?.map(e => e.businessName).sort();

        const businessName = businessList.map((eve, ind) => {
            return {
                id: `${eve.businessId}`,
                value: eve.businessName,
                BusinessID: eve.businessId
            }
        })
        return tempArray.concat(businessName);
    }


    useEffect(() => {

        if (state?.state?.type === 'VIEW') {
            setmatchingSta(true);
            setBusinessName({ id: `${businessID}`, value: state?.state?.businessNAme });
            addLocationForm.setFieldValue('businessName', state?.state?.businessNAme, { shouldValidate: true });
            addLocationForm.setFieldTouched('businessName', true);
        }

    }, [])


    useEffect(() => {
        if (state?.state?.type === 'LocEdit') {

            let fullContact = `${locationDetails?.bCountryCode || ''}${locationDetails?.bContactNumer || ''}`;

            setFullContactNumber(fullContact)

            setTypeCheck(state?.state?.type);
            //     id: locationDetails?.businessId,
            //     value: locationDetails?.businessName,
            //     BusinessID: locationDetails?.businessId
            // })
            setLatLng({
                lat: locationDetails?.latitude,
                lng: locationDetails?.longitude
            })
            setRegionLoc(locationDetails?.region);
            setCityLoc(locationDetails?.city);
            setPinLoc(locationDetails?.PINCode);
            setLatitude(locationDetails?.latitude);
            setLongitude(locationDetails?.longitude);
            if (locationDetails?.timings) {
                setBusinessName({ id: `${locationDetails?.businessId}`, value: locationDetails?.businessName })
                const locServiceTime = JSON.parse(locationDetails?.timings).map((eve, ind) => {
                    return {
                        id: ind + 1,
                        day: eve.day,
                        dayCheck: eve.dayCheck,
                        startTime: eve.startTime ? new Date(eve.startTime) : '',
                        endTime: eve.endTime ? new Date(eve.endTime) : '',
                        fullDayCheck: eve.fullDayCheck
                    }
                })

                setSearviceTimingData(locServiceTime);
            }
        }
    }, [locationDetails])

    useEffect(() => {
        if (locationList.length > 0) {
            const locListLatLong = locationList?.map((e, i) => {
                return {
                    id: `${i + 1}`,
                    markerPosition: {
                        lat: e.latitude,
                        lng: e.longitude
                    }
                }
            })
            setMapAllLocation(locListLatLong);

        } else {
            setMapAllLocation([]);
        }
    }, [locationList]);


    const locationCurrent = () => navigator.geolocation.getCurrentPosition(position => {
        setLatLng({ lat: position.coords.latitude, lng: position.coords.longitude })
    });




    const addLocationForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            locationName: (locationDetails && locationDetails?.locationName) || '',
            address: (locationDetails && locationDetails?.address) || '',
            // maximumpower: (locationDetails && locationDetails?.maxPowerSupply) || '',
            managerName: (locationDetails && `${locationDetails?.lFirstName} ${locationDetails?.lLastName}`) || '',
            email: (locationDetails && locationDetails?.lEmail) || '',
            contactNumber: (locationDetails && locationDetails?.lContactNumber) || '',
            businessName: locationDetails?.businessName,
            days: [...SearviceTimingData]
        },
        validationSchema: Yup.object(
            {
                locationName: Yup.string()
                    .required('Location name is required')
                    .matches(Constant.REGEX.ALPHANUMERIC, 'Alphanumeric characters only'),
                managerName: Yup.string()
                    .required('Manager name is required'),
                address: locationValid ? Yup.string()
                    .required('Address Field is required') : Yup.string().required('please select valid address or move the pin on map to update location'),
                email: Yup.string().max(50, 'Email address should be less than 50 alphabets')
                    .required('Email is required')
                    .matches(/^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i, 'Please provide a valid email address'),
                contactNumber: Yup.string()
                    .required('Contact number is required')
                    .matches(/^([0-9]{8,15})$/, 'Please enter a valid phone number'),
                businessName: Yup.string().required(),

                days: Yup.array().of(Yup.object().shape({
                    day: Yup.string(),
                    dayCheck: Yup.boolean(),
                    startTime: Yup.string(),
                    endTime: Yup.string(),
                    fullDayCheck: Yup.boolean()
                })).compact((v) => !v.dayCheck).min(1, 'use atleast one day')
                    .test('ww', 'Please enter valid start and end time', function (value, context) {
                        const { parent } = context;



                        return parent.days.find((item) => {
                            return ((item.dayCheck == true && item.fullDayCheck == false && item.startTime == undefined && item.endTime == undefined) || (item.dayCheck == true && item.startTime == undefined && item.fullDayCheck == false) || (item.dayCheck == true && item.endTime == undefined && item.fullDayCheck == false))
                        }) == undefined;
                    }),
            }),
        // days: Yup.array().of(Yup.object().shape({
        //     day: Yup.string(),
        //     dayCheck: Yup.boolean()
        //         .test('invalid time', 'need to input time correctly', function (value, context) {
        //             const { parent } = context;

        //             return (value == true && parent.fullDayCheck == true) || (parent.startTime !== undefined && parent.endTime !== undefined && value == true)
        //         }),
        //     startTime: Yup.string(),
        //     endTime: Yup.string(),
        //     fullDayCheck: Yup.boolean()
        // }))
        onSubmit: (values) => {

            if (typeCheck === 'LocEdit') {
                const editParams = {
                    locationName: values.locationName,
                    address: values.address,
                    region: regionLoc || '',
                    city: cityLoc,
                    PIN: Number(pinLoc) || '',
                    latitude: latitude,
                    longitude: longitude,
                    firstName: values.managerName.split(' ')[0],
                    lastName: values.managerName.split(' ').slice(1).join(' ') || '',
                    email: values.email,
                    countryCode: Number(phoneCode),
                    contactNumber: Number(phoneContact),
                    timings: values.days,
                    businessId: businessID
                    // timings: SearviceTimingData
                }

                dispatch(editLocation(editParams, locationId, props.history));

            } else {
                const addParams = {
                    locationName: values.locationName,
                    address: values.address,
                    region: regionLoc || '',
                    city: cityLoc,
                    PIN: Number(pinLoc) || '',
                    latitude: latitude,
                    longitude: longitude,
                    firstName: values.managerName.split(' ')[0],
                    lastName: values.managerName.split(' ').slice(1).join(' ') || '',
                    email: values.email,
                    countryCode: Number(phoneCode),
                    contactNumber: Number(phoneContact),
                    timings: values.days,

                    // timings: SearviceTimingData
                }
                dispatch(addNewLocation(addParams, businessID, props.history))

            }
        }
    });

    useEffect(() => {
        addLocationForm.validateForm();

    }, [])

    useEffect(() => {
        if (addressAuto) {
            addLocationForm.setFieldValue('address', addressAuto);
        }
    }, [addressAuto])

    const getAddress = (lat, long) => {

        if (lat && long) {
            fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${Constant.GOOGLEMAPKEY.ApiKey}`
            )
                .then((res) => res.json())
                .then((address) => {
                    setlocationValid(true)

                    // setAddressAuto(address.results[1]?.formatted_address);
                    setLatitude(address.results[0]?.geometry?.location?.lat)
                    setLongitude(address.results[0]?.geometry?.location?.lng)

                    const addressAuto = address.results?.find((i) => {
                        return (i.types.some(i => i === "route"))
                    });

                    setAddressAuto(addressAuto?.formatted_address);

                    const regionMap = address.results[1].address_components?.find((i) => {
                        return (i.types.some(i => i === "sublocality"))
                    });

                    setRegionLoc(regionMap?.long_name);
                    const cityMap = address.results[1].address_components?.find((i) => {
                        return (i.types.some(i => i === "administrative_area_level_3"))
                    });

                    setCityLoc(cityMap?.long_name);
                    const PINmap = address.results[1].address_components?.find((i) => {
                        return (i.types.some(i => i === "postal_code"))
                    });

                    setPinLoc(PINmap?.long_name);
                });
        }
    };



    // handle date validation
    const handleDateValidation = () => {

        const isValid1 = SearviceTimingData.find((item) => {
            return (item.dayCheck == true && item.fullDayCheck == false);
        })
        const isValid2 = SearviceTimingData.find((item) => {
            return (item.dayCheck == true && item.startTime === "");
        })
        const isValid3 = SearviceTimingData.find((item) => {
            return (item.dayCheck == true && item.endTime === "");
        })
        const isValid4 = SearviceTimingData.find((item) => {
            return (item.dayCheck == true && item.endTime === "");
        })
        if ((isValid1 !== undefined && isValid2 !== undefined) || (isValid1 !== undefined && isValid3 !== undefined) || isValid1 !== undefined) {
            setInvalid(true)
        } else {
            setInvalid(false);
        }

    }

    // useEffect(() => {
    //     handleDateValidation()
    // }, [SearviceTimingData])


    useEffect(() => {
        getAddress(latitude, longitude)
    }, []);

    // const emailAutoData = [
    //     {
    //         id: '1',
    //         emailName: 'mark@mailinator.com'
    //     },
    //  
    // ]
    // const center = {
    //     lat: 23.014870488676312,
    //     lng: 72.57344557869808
    // };
    // const markerItem = [
    //     {
    //         id: '1',
    //         markerPosition: {
    //             lat: 23.014870488676312,
    //             lng: 72.57344557869808
    //         }
    //     },
    //     {
    //         id: '2',
    //         markerPosition: {
    //             lat: 23.00613780313144,
    //             lng: 72.60116797407738
    //         }
    //     },

    // ]
    const businessNameList = [
        {
            id: '1',
            value: 'Phobos Pvt Ltd'
        },
        {
            id: '2',
            value: 'Titania Pvt Ltd'
        },
        {
            id: '3',
            value: 'Deimos LLP'
        },
        {
            id: '4',
            value: 'Hyperion LLP '
        },
        {
            id: '5',
            value: 'Phobos Pvt Ltd'
        },
        {
            id: '6',
            value: 'Nereid LLC'
        }
    ]

    useEffect(() => {
        if (window.location.href.includes('edit')) {
            setFixed(true);
        }
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        onClickBack={(e) => {
                            // history.push('/location')
                            e.preventDefault();
                            history.goBack();
                        }}
                        isButton={true}
                        title={pathname === '/location/editlocation' ? "Edit loaction" : "Add location"}
                        pageTitle="Location"
                        OnClickpageTitle={(e) => { e.preventDefault(); history.goBack(); }}
                    />
                    <div className='addLocationPage'>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            addLocationForm.handleSubmit();
                            return false;
                        }}
                            action="#"
                        >
                            <Row key={''}>
                                <Col xxl={7} xl={12} lg={12} md={12} className="lefPart">
                                    <Card className="">
                                        <CardHeader>
                                            <Row>
                                                <Col lg={12}>
                                                    <p className='headerText'>
                                                        Location Details
                                                    </p>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                            <Row>
                                                {window.localStorage.getItem('users') !== null && window.localStorage.getItem('users') !== undefined && JSON.parse(window.localStorage.getItem('users')).userRole !== 'BusinessOwner' && <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="locationName" className="form-label requierdLabel">
                                                            Business Name</Label>
                                                        <CustomDropdown
                                                            defaultValue={''}
                                                            selectedValue={businessName}
                                                            dropDownItems={allBusinessName || []}
                                                            placeholder={'Select Business Name'}
                                                            readOnly={true}
                                                            onSelect={(evt) => {
                                                                setBusinessName(evt);
                                                                setBusinessID(evt.BusinessID);
                                                                setmatchingSta(true);
                                                                addLocationForm.handleChange('businessName')(evt.value);

                                                            }}
                                                        />
                                                    </div>
                                                </Col>}
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="locationName" className="form-label requierdLabel">
                                                            Location Name</Label>
                                                        <Input
                                                            // defaultValue="Dave"
                                                            name='locationName'
                                                            type="text"
                                                            className="form-control"
                                                            id="firstnameInput"
                                                            placeholder="Enter Location Name"
                                                            maxLength={100}
                                                            value={addLocationForm.values.locationName.trimStart()}
                                                            onChange={addLocationForm.handleChange}
                                                            onKeyUp={addLocationForm.handleBlur}
                                                            invalid={
                                                                addLocationForm.errors.locationName && addLocationForm.touched.locationName ? true : false
                                                            }
                                                        />
                                                        {addLocationForm.errors.locationName && addLocationForm.touched.locationName ? (
                                                            <FormFeedback type="invalid">{addLocationForm.errors.locationName}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="firstnameInput" className="form-label requierdLabel">
                                                            Address</Label>
                                                        <div className="search-box">
                                                            <Autocomplete
                                                                // defaultValue={addressAuto}
                                                                placeholder='Enter Address'
                                                                id="address"
                                                                value={addLocationForm.values.address.trimStart() || ''}
                                                                className='autoCompleteInput'
                                                                componentRestrictions={{ country: "in" }}
                                                                options={{
                                                                    types: ["geocode", "establishment"],
                                                                }}

                                                                apiKey={Constant.GOOGLEMAPKEY.ApiKey}
                                                                // onChange={addLocationForm.handleChange}
                                                                onChange={(e) => { addLocationForm.handleChange('address')(e.target.value); if (e.target.value == '') { setlocationValid(false) } }}
                                                                onKeyUp={addLocationForm.handleBlur}
                                                                onPlaceSelected={(place) => {

                                                                    if (place.formatted_address !== undefined) {
                                                                        addLocationForm.handleChange('address')(place.formatted_address)

                                                                        setlocationValid(true)

                                                                        const regionMap = place.address_components?.find((i) => {
                                                                            return (i.types.some(i => i === "sublocality"))
                                                                        });
                                                                        setRegionLoc(regionMap?.long_name);


                                                                        const cityMap = place.address_components?.find((i) => {
                                                                            return (i.types.some(i => i === "administrative_area_level_3"))
                                                                        });
                                                                        setCityLoc(cityMap?.long_name);


                                                                        const PINmap = place.address_components?.find((i) => {
                                                                            return (i.types.some(i => i === "postal_code"))
                                                                        });
                                                                        setPinLoc(PINmap?.long_name);


                                                                        setLatLng({
                                                                            lat: place.geometry.location.lat(),
                                                                            lng: place.geometry.location.lng()

                                                                        })
                                                                        setAddressAuto(place.formatted_address)
                                                                        setLatitude(place.geometry.location.lat());
                                                                        setLongitude(place.geometry.location.lng());

                                                                    } else {
                                                                        setlocationValid(false);
                                                                    }
                                                                }}
                                                            />
                                                            <i className="ri-search-line search-icon"></i>
                                                        </div>
                                                        {addLocationForm.errors.address && addLocationForm.touched.address ? <div type='invaild' className='errorMessageShow'>Address field is required</div> : null}
                                                        {!locationValid && !addLocationForm.errors.address && addLocationForm.touched.address ? <div type='invaild' className='errorMessageShow'>Please select valid address or move the pin on map to update location</div> : null}

                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <hr />
                                                    <div className='mapContainer'>
                                                        <MapComponent isMultipleMarker={true} markerItem={mapAllLocation} mapCenter={latlng} onChange={(newValue) => {
                                                            setLatLng({
                                                                lat: newValue.lat,
                                                                lng: newValue.lng
                                                            })
                                                            getAddress(newValue.lat, newValue.lng);
                                                            setLatitude(newValue.lat);
                                                            setLongitude(newValue.lng);

                                                        }}

                                                        />
                                                    </div>
                                                </Col>
                                                {/* <Col lg={12}>
                                                    <div className="mb-2 mt-2">
                                                        <Label htmlFor="maximumpower" className="form-label">Maximum power supply (Opt.)</Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="maximumpower"
                                                            placeholder="Enter power supply"
                                                            onChange={addLocationForm.handleChange}
                                                            value={addLocationForm.values.maximumpower}
                                                        />
                                                    </div>
                                                </Col> */}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={5} xl={12} lg={12} md={12} className='rightPart'>
                                    <Row>
                                        <Col xl={12} lg={12}>
                                            <Card className="">
                                                <CardHeader>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <p className='headerText'>
                                                                Manager Details
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody className="p-4 ">
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="emailInput" className="form-label requierdLabel">Email
                                                                    Address</Label>
                                                                <div className='emailAuto'>
                                                                    <Input
                                                                        name='email'
                                                                        type="text"
                                                                        className={pathname === '/businesses/editbusiness' ? 'form-control readEmail' : 'form-control'}
                                                                        // readOnly={typeCheck === 'LocEdit' ? false : matchingSta}
                                                                        readOnly={typeCheck === 'LocEdit' ? false : (businessName.length < 1)}
                                                                        id="emailInput"
                                                                        placeholder="Enter Email Address"
                                                                        onChange={(e) => {
                                                                            setFixed(false)
                                                                            addLocationForm.handleChange(e);
                                                                            matchingSta && setRead(false);
                                                                            setChangeEmailValue(true);
                                                                            if (e.target.value.length >= 3) {
                                                                                dispatch(getMatchEmail('role=LocationManager', `email=${e.target.value}&businessId=${businessID}`))
                                                                            } else {
                                                                                setChangeEmailValue(false);
                                                                            }
                                                                        }}
                                                                        onKeyUp={addLocationForm.handleBlur}
                                                                        value={addLocationForm.values.email.trimStart() || ''}
                                                                        invalid={
                                                                            addLocationForm.errors.email && addLocationForm.touched.email ? true : false
                                                                        }
                                                                    />
                                                                    {/* email autocomplete */}
                                                                    {
                                                                        changeEmailValue && getMatchingEmail?.length > 0 &&
                                                                        <OutsideClickHandler
                                                                            onOutsideClick={() => {
                                                                                setChangeEmailValue(false);

                                                                            }}
                                                                        >
                                                                            <div className='menu'>
                                                                                <ul>
                                                                                    {
                                                                                        getMatchingEmail.map((item, index) => {
                                                                                            return (
                                                                                                <li key={index} onClick={() => {
                                                                                                    setFixed(true);
                                                                                                    addLocationForm.setFieldValue('email', item?.email, { shouldValidate: true });
                                                                                                    setTimeout(() => {
                                                                                                        addLocationForm.setFieldValue('managerName', `${item?.firstName} ${item?.lastName}`, { shouldValidate: true })
                                                                                                        addLocationForm.setFieldValue('contactNumber', `${item?.contactNumber}`, { shouldValidate: true });
                                                                                                        setRead(true);
                                                                                                    }, 500)
                                                                                                    setTimeout(() => {
                                                                                                        addLocationForm.setFieldTouched('managerName', true);
                                                                                                        addLocationForm.setFieldTouched('contactNumber', true);
                                                                                                    }, 1000)


                                                                                                    setChangeEmailValue(false);
                                                                                                }}>{item?.email}</li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </OutsideClickHandler>
                                                                    }
                                                                    {addLocationForm.errors.email && addLocationForm.touched.email ? (
                                                                        <FormFeedback type="invalid">{addLocationForm.errors.email}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="Name" className={businessManeger ? "form-label" : "form-label requierdLabel"}>{businessManeger ? 'Name (Opt.)' : 'Name'}</Label>
                                                                <Input

                                                                    id="Name"
                                                                    name='managerName'
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Name"
                                                                    maxLength={50}
                                                                    // readOnly={read}
                                                                    readOnly={typeCheck === 'LocEdit' ? fixed : (businessName.length < 1 || fixed)}
                                                                    value={addLocationForm.values.managerName.trimStart() || ''}
                                                                    onChange={addLocationForm.handleChange}
                                                                    onKeyUp={addLocationForm.handleBlur}
                                                                    invalid={
                                                                        addLocationForm.errors.managerName && addLocationForm.touched.managerName ? true : false
                                                                    }
                                                                />
                                                                {addLocationForm.errors.managerName && addLocationForm.touched.managerName ? (
                                                                    <FormFeedback type="invalid">{addLocationForm.errors.managerName}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="mb-3 phoneDiv">
                                                                <Label htmlFor="ContactNumber" className={businessManeger ? "form-label" : "form-label requierdLabel"}>{businessManeger ? 'Contact Number (Opt.)' : 'Contact Number'}</Label>
                                                                <PhoneInput
                                                                    className={'phoneinput textField'}
                                                                    placeholder={'Enter your phone number'}
                                                                    enableSearch={true}
                                                                    showDropdown={false}
                                                                    // priority={{ in: 1 }}
                                                                    inputProps={{
                                                                        name: 'contactNumber',
                                                                        required: true,
                                                                    }}
                                                                    country={'ae'}
                                                                    countryCodeEditable={false}
                                                                    // value={businessDetail[0]?.countryCode + businessDetail[0]?.contactNumber}
                                                                    value={fullContactNumber || ''}

                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        console.log(country, 'country')


                                                                        // const phoneNumber = parsePhoneNumberFromString(value, countryData.countryCode.toUpperCase());
                                                                        const phoneNumber = parsePhoneNumberFromString(value, country.countryCode.toUpperCase());



                                                                        // console.log(formattedValue.split(' ')[0], 'country code')
                                                                        // console.log(formattedValue.split(' ')[1], 'Phone number')

                                                                        let [firstPart, ...restParts] = formattedValue.split(' ');
                                                                        // Join the rest of the parts, remove all special characters and spaces
                                                                        let cleanedPart = restParts.join('').replace(/[^\d+]/g, '');
                                                                        firstPart = firstPart.replace(/^\+/, '');
                                                                        setPhoneTouched(true);
                                                                        setPhoneCode(firstPart)
                                                                        setPhoneContact(cleanedPart)
                                                                        if (phoneNumber.isValid()) {
                                                                            setIsValid(phoneNumber.isValid());
                                                                            addLocationForm.setFieldValue('contactNumber', cleanedPart);
                                                                        } else {
                                                                            setIsValid(false)
                                                                            addLocationForm.setFieldValue('contactNumber', '');
                                                                        }


                                                                    }}

                                                                // onChange={(value, country, e, formattedValue) => {
                                                                //     // console.log(formattedValue.split(' ')[0], 'country code')
                                                                //     // console.log(formattedValue.split(' ')[1], 'Phone number')


                                                                //     let [firstPart, ...restParts] = formattedValue.split(' ');
                                                                //     // Join the rest of the parts, remove all special characters and spaces
                                                                //     let cleanedPart = restParts.join('').replace(/[^\d+]/g, '');
                                                                //     // console.log(/^([0-9]{8,15})$/.test(cleanedPart), 'Condition')
                                                                //     firstPart = firstPart.replace(/^\+/, '');
                                                                //     setPhoneTouched(true);
                                                                //     setPhoneCode(firstPart)
                                                                //     setPhoneContact(cleanedPart)
                                                                //     if (!phoneTouched) {

                                                                //         if (typeCheck === 'Edit') {
                                                                //             console.log(phoneTouched, 'phoneTouched')

                                                                //             addLocationForm.setFieldValue('contactNumber', cleanedPart);
                                                                //         }
                                                                //         return true
                                                                //     }
                                                                //     else if (/^([0-9]{8,15})$/.test(cleanedPart)) {
                                                                //         setPhoneNumberError('')
                                                                //         addLocationForm.setFieldValue('contactNumber', cleanedPart);

                                                                //     } else {
                                                                //         if (cleanedPart == '') {
                                                                //             setPhoneNumberError('Please enter phone number')
                                                                //             addLocationForm.setFieldValue('contactNumber', '');
                                                                //             return;
                                                                //         } else {
                                                                //             setPhoneNumberError('Please enter valid phone number')
                                                                //             addLocationForm.setFieldValue('contactNumber', '');

                                                                //             return;
                                                                //         }
                                                                //     }
                                                                // }}
                                                                //  isValid={() => phoneTouched ? /^[0-9]{8,15}$/.test(phoneNumbers) : true}
                                                                />
                                                                {isValid !== null && (
                                                                    <div style={{ color: "#f06548" }}>
                                                                        {isValid ? '' : 'Contact number is not valid'}
                                                                    </div>
                                                                )}
                                                                {/* {phoneNumberError !== '' && phoneTouched &&
                                                                    <div className=" invalid-feedback"><span>{phoneNumberError}</span></div>
                                                                } */}
                                                                {/* <InputMask mask={'+\\91 9999999999'} maskChar={''}
                                                                    name='contactNumber'
                                                                    className={addLocationForm.errors.contactNumber && addLocationForm.touched.contactNumber ? "form-control is-invalid" : "form-control"}
                                                                    id="phonenumberInput"
                                                                    placeholder="Enter Contact Number"
                                                                    // onChange={addLocationForm.handleChange}
                                                                    onChange={(e) => {

                                                                        addLocationForm.handleChange('contactNumber')(e.target.value);
                                                                    }}
                                                                    onKeyUp={addLocationForm.handleBlur}
                                                                    value={addLocationForm.values.contactNumber || ''}
                                                                    // readOnly={read}
                                                                    readOnly={typeCheck === 'LocEdit' ? fixed : (businessName.length < 1 || fixed)}

                                                                /> */}
                                                                {addLocationForm.errors.contactNumber && addLocationForm.touched.contactNumber ? (
                                                                    <FormFeedback >{addLocationForm.errors.contactNumber}</FormFeedback>

                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xl={12} lg={12}>
                                            <Card className="serviceCard">
                                                <CardHeader>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <p className='headerText'>
                                                                Service Timing
                                                            </p>
                                                            <p className='errorTxt'>
                                                                {!addLocationForm.values.days.some((a) => a.dayCheck === true) ? 'Please choose atleast one day'
                                                                    : !addLocationForm.values.days.every((a) => {
                                                                        if (a.dayCheck) {
                                                                            return (a.startTime !== '' && a.endTime !== '') || (a.fullDayCheck === true)
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }) && 'Start Time/End Time must required'}
                                                                {/* {addLocationForm !== undefined && addLocationForm.errors?.days} */}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody className="p-4 ">
                                                    <Row className='timerRow'>
                                                        {/* table-responsive */}
                                                        <div className="">
                                                            <Table className="table-borderless align-middle table-nowrap mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Available Days</th>
                                                                        <th scope="col">Start Time</th>
                                                                        <th scope="col">End Time</th>
                                                                        <th scope="col">24 Hours</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {/* {SearviceTimingData.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>

                                                                                <td>
                                                                                    <div className="form-check form-check-success">
                                                                                        <Input
                                                                                            className={!SearviceTimingData.some((a) => a.dayCheck === true) ? "form-check-input error" : "form-check-input"}
                                                                                            type="checkbox"
                                                                                            id={item.id}
                                                                                            name='checkDay'
                                                                                            checked={item.dayCheck}
                                                                                            // disabled={item.fullDayCheck}
                                                                                            onChange={(e) => {
                                                                                                SearviceTimingData[index].dayCheck = SearviceTimingData[index].dayCheck === false ? true : false
                                                                                                SearviceTimingData[index].fullDayCheck = SearviceTimingData[index].dayCheck === false ? false : false
                                                                                                setSearviceTimingData([...SearviceTimingData]);
                                                                                                setCheckService(false);
                                                                                                
                                                                                                handleDateValidation()
                                                                                            }}
                                                                                        />
                                                                                        <Label className="form-check-label" for="formCheck1">
                                                                                            {item.day}
                                                                                        </Label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='timerDiv'>
                                                                                        <ReactDatePicker
                                                                                            autoComplete="off"
                                                                                            placeholderText='--:-- --'
                                                                                            className={(!item.dayCheck || item.fullDayCheck) ? 'simpleTimePicker disabled' : `simpleTimePicker ${item.dayCheck && !item.startTime && 'error'}`}
                                                                                            selected={item.startTime}
                                                                                            onChange={(e) => {
                                                                                                
                                                                                                // SearviceTimingData[index].startTime = moment(e).format('HH:mm');
                                                                                                SearviceTimingData[index].startTime = e;
                                                                                                SearviceTimingData[index].endTime = '';
                                                                                                setSearviceTimingData([...SearviceTimingData]);
                                                                                                setTime1(false);
                                                                                                setTime2(true);
                                                                                                handleDateValidation();
                                                                                            }}
                                                                                            showTimeSelect
                                                                                            showTimeSelectOnly
                                                                                            disabled={!item.dayCheck || item.fullDayCheck}
                                                                                            name='startTime'
                                                                                            timeIntervals={30}
                                                                                            timeCaption="Time"
                                                                                            dateFormat="h:mm aa"
                                                                                        />
                                                                                        <i className={!item.dayCheck || item.fullDayCheck ? 'mdi mdi-clock-time-four-outline fs-16 disabled' : 'mdi mdi-clock-time-four-outline fs-16'}></i>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='timerDiv'>
                                                                                        <ReactDatePicker
                                                                                            autoComplete="off"
                                                                                            placeholderText='--:-- --'
                                                                                            className={(!item.dayCheck || item.fullDayCheck) ? 'simpleTimePicker disabled' : `simpleTimePicker ${item.dayCheck && !item.endTime && 'error'}`}
                                                                                            selected={item.endTime}
                                                                                            name='endTime'
                                                                                            onChange={(e) => {
                                                                                                SearviceTimingData[index].endTime = e;
                                                                                                // SearviceTimingData[index].endTime = moment(e).format('HH:mm');
                                                                                                setSearviceTimingData([...SearviceTimingData]);
                                                                                                setTime2(false);
                                                                                                handleDateValidation();
                                                                                            }}
                                                                                            showTimeSelect
                                                                                            minTime={item.startTime ? new Date(moment(item.startTime).add(30, 'minutes').format()) : setHours(setMinutes(new Date(), 0), 0)}
                                                                                            maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                                                                            showTimeSelectOnly
                                                                                            disabled={!item.dayCheck || item.fullDayCheck}
                                                                                            timeIntervals={30}
                                                                                            timeCaption="Time"
                                                                                            dateFormat="h:mm aa"
                                                                                        />
                                                                                        <i className={!item.dayCheck || item.fullDayCheck ? 'mdi mdi-clock-time-four-outline fs-16 disabled' : 'mdi mdi-clock-time-four-outline fs-16'}></i>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-check form-check-outline form-check-success">
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            id={item.id}
                                                                                            name={`days[${index}].fullDayCheck`}
                                                                                            disabled={!item.dayCheck}
                                                                                            checked={item.fullDayCheck}
                                                                                            onChange={(e) => {
                                                                                                SearviceTimingData[index].fullDayCheck = SearviceTimingData[index].fullDayCheck === false ? true : false
                                                                                                SearviceTimingData[index].endTime = '';
                                                                                                SearviceTimingData[index].startTime = '';
                                                                                                setSearviceTimingData([...SearviceTimingData]);
                                                                                                setFullcheck(e.target.checked);
                                                                                                
                                                                                                handleDateValidation();
                                                                                                
                                                                                            }}

                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        )
                                                                    })} */}
                                                                    <FormikProvider value={addLocationForm}>
                                                                        <FieldArray name='days'>
                                                                            <>
                                                                                {/* {SearviceTimingData.map((item, index) => { */}
                                                                                {addLocationForm.values.days.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index}>

                                                                                            <td>
                                                                                                <div className="form-check form-check-success">
                                                                                                    <Input
                                                                                                        className={!addLocationForm.values.days.some((a) => a.dayCheck === true) ? "form-check-input error" : "form-check-input"}
                                                                                                        type="checkbox"
                                                                                                        // id={item.id}
                                                                                                        // name='checkDay'
                                                                                                        name={`days[${index}].dayCheck`}
                                                                                                        checked={item.dayCheck}
                                                                                                        // disabled={item.fullDayCheck}
                                                                                                        // onChange={addLocationForm.handleChange}
                                                                                                        onChange={(e) => {
                                                                                                            // SearviceTimingData[index].dayCheck = SearviceTimingData[index].dayCheck === false ? true : false
                                                                                                            // SearviceTimingData[index].fullDayCheck = SearviceTimingData[index].dayCheck === false ? false : false
                                                                                                            // setSearviceTimingData([...SearviceTimingData]);
                                                                                                            // setCheckService(false);
                                                                                                            handleDateValidation()

                                                                                                            addLocationForm.handleChange(`days[${index}].dayCheck`)(e)
                                                                                                            if (e.target.checked == false) {
                                                                                                                addLocationForm.handleChange(`days[${index}].fullDayCheck`)(e)
                                                                                                                addLocationForm.handleChange(`days[${index}].startTime`)('')
                                                                                                                addLocationForm.handleChange(`days[${index}].endTime`)('')
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                    <Label className="form-check-label" for="formCheck1">
                                                                                                        {item.day}
                                                                                                    </Label>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className='timerDiv'>
                                                                                                    <ReactDatePicker
                                                                                                        autoComplete="off"
                                                                                                        placeholderText='--:-- --'
                                                                                                        className={(!item.dayCheck || item.fullDayCheck) ? 'simpleTimePicker disabled' : `simpleTimePicker ${item.dayCheck && !item.startTime && 'error'}`}
                                                                                                        selected={Date.parse(item.startTime)}
                                                                                                        name={`days[${index}].startTime`}
                                                                                                        onKeyDown={(e) => { e.preventDefault(); }}
                                                                                                        // onBlur={(e) => { addLocationForm.handleBlur(`days[${index}].startTime`)(new Date(e).toISOString()) }}
                                                                                                        onChange={(e) => {


                                                                                                            // SearviceTimingData[index].startTime = moment(e).format('HH:mm');
                                                                                                            // SearviceTimingData[index].startTime = e;
                                                                                                            // SearviceTimingData[index].endTime = '';
                                                                                                            // setSearviceTimingData([...SearviceTimingData]);
                                                                                                            // setTime1(false);
                                                                                                            // setTime2(true);
                                                                                                            // handleDateValidation();

                                                                                                            const x = e.toISOString();
                                                                                                            addLocationForm.handleChange(`days[${index}].startTime`)(x)
                                                                                                            addLocationForm.handleChange(`days[${index}].endTime`)('')

                                                                                                        }}
                                                                                                        showTimeSelect
                                                                                                        showTimeSelectOnly
                                                                                                        disabled={!item.dayCheck || item.fullDayCheck}
                                                                                                        // name='startTime'
                                                                                                        timeIntervals={5}
                                                                                                        timeCaption="Time"
                                                                                                        dateFormat="h:mm aa"
                                                                                                    />
                                                                                                    <i className={!item.dayCheck || item.fullDayCheck ? 'mdi mdi-clock-time-four-outline fs-16 disabled' : 'mdi mdi-clock-time-four-outline fs-16'}></i>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className='timerDiv'>
                                                                                                    <ReactDatePicker
                                                                                                        autoComplete="off"
                                                                                                        placeholderText='--:-- --'
                                                                                                        className={(!item.dayCheck || item.fullDayCheck) ? 'simpleTimePicker disabled' : `simpleTimePicker ${item.dayCheck && !item.endTime && 'error'}`}
                                                                                                        selected={Date.parse(item.endTime)}
                                                                                                        // name='endTime'
                                                                                                        name={`days[${index}].endTime`}
                                                                                                        onKeyDown={(e) => { e.preventDefault(); }}
                                                                                                        // onBlur={addLocationForm.handleBlur}
                                                                                                        onChange={(e) => {
                                                                                                            // SearviceTimingData[index].endTime = e;
                                                                                                            // SearviceTimingData[index].endTime = moment(e).format('HH:mm');
                                                                                                            // setSearviceTimingData([...SearviceTimingData]);
                                                                                                            // setTime2(false);
                                                                                                            // handleDateValidation();
                                                                                                            const x = e.toISOString();
                                                                                                            addLocationForm.handleChange(`days[${index}].endTime`)(x)
                                                                                                        }}
                                                                                                        showTimeSelect
                                                                                                        minTime={item.startTime ? new Date(moment(item.startTime).add(5, 'minutes').format()) : setHours(setMinutes(new Date(), 0), 0)}
                                                                                                        maxTime={setHours(setMinutes(new Date(), 55), 23)}
                                                                                                        showTimeSelectOnly
                                                                                                        disabled={!item.dayCheck || item.fullDayCheck}
                                                                                                        timeIntervals={5}
                                                                                                        timeCaption="Time"
                                                                                                        dateFormat="h:mm aa"
                                                                                                    />
                                                                                                    <i className={!item.dayCheck || item.fullDayCheck ? 'mdi mdi-clock-time-four-outline fs-16 disabled' : 'mdi mdi-clock-time-four-outline fs-16'}></i>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-check form-check-outline form-check-success">
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        // id={item.id}
                                                                                                        name={`days[${index}].fullDayCheck`}
                                                                                                        // name={`days[${index}].startTime`}
                                                                                                        disabled={!item.dayCheck}
                                                                                                        checked={item.fullDayCheck}
                                                                                                        onChange={(e) => {
                                                                                                            // SearviceTimingData[index].fullDayCheck = SearviceTimingData[index].fullDayCheck === false ? true : false
                                                                                                            // SearviceTimingData[index].endTime = '';
                                                                                                            // SearviceTimingData[index].startTime = '';
                                                                                                            // setSearviceTimingData([...SearviceTimingData]);
                                                                                                            // setFullcheck(e.target.checked);

                                                                                                            // handleDateValidation();

                                                                                                            addLocationForm.handleChange(`days[${index}].fullDayCheck`)(e)
                                                                                                            if (e.target.checked == true) {
                                                                                                                addLocationForm.handleChange(`days[${index}].startTime`)('')
                                                                                                                addLocationForm.handleChange(`days[${index}].endTime`)('')
                                                                                                            }
                                                                                                        }}

                                                                                                    />
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        </FieldArray>
                                                                    </FormikProvider>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={12}>
                                    <div className="hstack gap-2  pt-4 justify-content-start">
                                        <CustomButton
                                            title={pathname === '/location/editlocation' ? "Update Location" : "Add Location"}
                                            disabled={
                                                typeCheck === "LocEdit" ? !(addLocationForm.isValid && addLocationForm.dirty && locationValid && addLocationForm.values.days.some((item) => {
                                                    return item.dayCheck == true
                                                })) : !(addLocationForm.isValid && locationValid && addLocationForm.values.days.some((item) => {
                                                    return item.dayCheck == true
                                                }))
                                            }
                                            // disabled={typeCheck === 'LocEdit'
                                            //     ? !(checkService) ? (((time1 === false) && (time2 === false)) || fullcheck ? false : true) :
                                            //         !(addLocationForm.isValid && addLocationForm.dirty)
                                            //     // ? !((addLocationForm.dirty && addLocationForm.isValid) && !Invalid) // : !(addLocationForm.isValid && addLocationForm.dirty && !Invalid)}
                                            //     // ? !(addLocationForm.isValid) : !(addLocationForm.isValid)}
                                            //     // ? Invalid
                                            //     : !(addLocationForm.isValid && addLocationForm.dirty && SearviceTimingData.some((a) => a.dayCheck === true) && (SearviceTimingData.every((a) => {
                                            //         if (a.dayCheck) {
                                            //             return a.startTime !== '' && a.endTime !== ''
                                            //         } else {
                                            //             return true;
                                            //         }
                                            //     }) || SearviceTimingData.some((a) => a.fullDayCheck === true))
                                            //     )}
                                            type='submit'
                                            loading={loading}
                                            className='btn addButtonWithLoader'
                                            btnIcon={pathname === '/location/editlocation' ? '' : "ri-add-line align-middle fs-16 me-2"}
                                        />
                                        <Button
                                            type="button"
                                            className="btn cancelBtn btn-soft"
                                            onClick={() => {
                                                // history.push('/location')
                                                history.goBack();
                                            }}>
                                            Cancel</Button>

                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddLocation;